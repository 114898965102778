@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');
.add-icon::before {
    /* content: url("./favicon.png"); */
    position: relative;
    left: 0;
    top: 40px;
}
.title-main {
  position: relative;
  font-weight: 900;
  font-size: 5rem;
  line-height: 110px;
  text-transform: uppercase;
  color: #4F4F4F;
}

.icon {
  position: absolute;
  display: inline-block;
  /* background-image: url("assets/icon.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  top: 80px;
}

.btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* background: repeating-linear-gradient(-75deg, rgba(0, 0, 0, 0.08) 0, 1.5px, transparent 1px 7px), linear-gradient(94.77deg, #8EB7CA 0%, #6BB748 100%); */
  background-color: #8EB7CA;
  border-radius: 500px;
  font-weight: 800;
  font-size: 19px;
  line-height: 18px;
  /* text-transform: uppercase; */
  color: #FFFFFF;
  border-style: none;
  padding: 15px 34px;
}

.btn_shadow {
  -webkit-box-shadow: 0px 20px 20px rgba(115, 205, 0, 0.3);
          box-shadow: 0px 20px 20px rgba(115, 205, 0, 0.3);
}

.btn:hover {
  cursor: pointer;
}

/* Header */
.header {
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
  padding: 0 50px 0;
}

.header-item {
  display: flex;
  align-items: center;
}

.header__little-img {
  display: none;
}

.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.docs-wrapper .header__logo {
  justify-content: center;
}

.header__name {
  /* margin-left: 12px; */
  /* height: 43px; */
}

.header__name-agro {
  font-size: 20px;
  font-weight: bolder;
  color: #27AE60;
  line-height: 15px;
}
.profile-wrapper .header__name-agro {
  font-size: 30px;
  font-weight: bolder;
  color: #27AE60;
  line-height: 20px;
}

.header__name-bot {
  font-size: 20px;
  font-weight: bolder;
  color: #000000;
  line-height: 15px;
}
.profile-wrapper .header__name-bot {
  font-size:30px;
  font-weight: bolder;
  color: #000000;
  line-height: 20px;  
}

.header__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 725px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.header__item {
  list-style: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  height: 32px;
  margin: 0 20px;
}

.header__item_main {
  /* background-image: url("assets/round.svg"); */
  background-position: top;
  background-repeat: no-repeat;
}

.header__item:hover {
  /* background-image: url("assets/round.svg"); */
  background-position: top;
  background-repeat: no-repeat;
}

.header__link {
  text-decoration: none;
  color: #828282;
  white-space: nowrap;
}

.header__link_main {
  /* color: #4F4F4F; */
}

.header__link:hover {
  /* color: #4F4F4F; */
}

.header__buttons {
  height: 120px;
  width: 180px;
}

.header__btncontact {
  position: absolute;
  top: 55px;
  right: 49px;
  border: none;
  height: 58px;
  width: 164px;
  background: #4B6350;
  color: #FFFFFF;
  border-radius: 0px 0px 22px 22px;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  padding: 29px 38px 11px 38px;
}

.header__btnphone {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 13px 21px;
  position: absolute;
  top: 33px;
  right: 49px;
  height: 44px;
  width: 180px;
}

.button-subscribe {
  border: none;
  color: #FFFFFF;
  border-radius: 22px;
  /* background: ; */
  /* background: url("assets/btn_stripes.svg"), linear-gradient(94.77deg, #8EB7CA 0%, #6BB748 100%); */
  opacity: 1, 0;
}

.burger {
  display: none;
}

/* AGROBOT */
.agrobot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 50px 0px 50px;
}

.agrobot__btn {
  padding: 15px 25px;
  margin-left: 0px;
}

.banner {
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.banner__title {
  font-family: 'Raleway', sans-serif;;
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: revert;
  color: #383838;
  /* margin-top: 21%; */
  margin-bottom: 0;
  /* line-height: 20px; */
}

.contact-button {
  width: 320px;
  height: 62px;
}

.banner-top {
  font-size: 4vw;
  line-height: 50px;
  margin-top: 10%;
}

.banner__title_green {
  color: #8EB7CA;
}

.banner__description {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: black;
  margin: 20px;
}

.banner__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  max-width: 300px;
  margin-bottom: 30px;
}

.banner__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  max-width: 100%;
}

.banner__img_small {
  display: inline-block;
}

.banner__img-block {
  text-align: center;
}

/* Товары и услуги */
.goods {
  /* padding: 0px 50px 30px 50px; */
  position: relative;
  overflow: hidden;
}

.goods-flex {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
}

.goods-funcs {
  display: flex;
  justify-content: space-around;
  margin: 230px 0;
}

.goods-funcs > div {
  display: flex;
  flex-direction: column;
}

.goods-funcs > div > p {
  display: flex;
  flex-direction: column;
  color: #383838;
  font-size: 28px;
}

.goods-funcs > div > b {
  display: flex;
  flex-direction: column;
  color: #27AE60;
  font-size: 38px;
}

.goods-flex > img {
  /* height: 100%; */
}

.goods__background {
  position: absolute;
  background: #F2F2F2;
  opacity: 0.5;
  border-radius: 116px;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  z-index: -1;
}

.goods__background_stripe1 {
  width: 232px;
  height: 512px;
  left: -210px;
  top: 214px;
}

.goods__background_stripe2 {
  width: 232px;
  height: 911px;
  left: 208px;
  top: 17px;
}

.goods__background_stripe3 {
  width: 232px;
  height: 338px;
  left: 586px;
  top: 238px;
}

.goods__background_stripe4 {
  width: 232px;
  height: 923px;
  right: 207px;
  top: -55px;
}

.goods__background_stripe5 {
  width: 232px;
  height: 472px;
  right: -217px;
  top: 185px;
}

.goods__icon {
  margin-left: -25px;
}

.goods__title {
  margin: 98px 0px 50px 25px;
}

.goods__text {
  color: #828282;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 49px;
}

.funcs {
  margin: 0 200px 200px 200px;
}
.funcs h2, .contact h2, .map h2 {
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  color: #383838;
  margin: 30px 0;
}

.funcs p, .contact p, .map p {
  font-weight: 400;
  font-size: 18px;
  /* line-height: 130%; */
  color: #000000;
  text-align: center;
  margin: 30px 0;
}

.funcs > div {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
}

.funcs > div > img {
  width: 100%;
}

.funcs > div > div > img {
  width: 50%;
}


.catalog {
  display: flex;
  /* flex-wrap: wrap; */
}

.product {
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
  padding: 2.5em 0.5em;
  width: 20%;
  margin: 10px auto;
  height: 100%;
  min-width: fit-content;
}

.product__icon {
  width: 124px;
  height: 124px;
  background-size: cover;
  margin: 0 auto;
  margin-bottom: 42px;
}

.product__icon_seeds {
  /* background-image: url("assets/seeds.svg"); */
}

.product__icon_branch {
  /* background-image: url("assets/branch.svg"); */
}

.product__icon_lightning {
  /* background-image: url("assets/lightning.svg"); */
}

.product__icon_tractor {
  /* background-image: url("assets/tractor.svg"); */
}
.product__icon_magnifier {
  /* background-image: url("assets/glass_icon.svg"); */
}
.product__icon_check {
  /* background-image: url("assets/check_icon.svg"); */
}

.product__title {
  font-weight: 700;
  color: #828282;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 5px;
}

.product__description {
  font-weight: 500;
  color: #BDBDBD;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 30px;
}

.product__btn {
  margin: 0 auto;
}

.product__arrow {
  display: none;
}

.bot {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 10px;
  left: 50px;
}

.bot__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #BDBDBD;
  background-color: transparent;
  /* min-height: 70px; */
  padding-left: 23px;
  margin: 20px;
  padding: 10px 30px 10px 10px;
  border-radius: 20px;
  /* width: 440px; */
}

/* Как с нами работать */
.work {
  position: relative;
  padding: 20px 22px 80px 50px;
  overflow: hidden;
  margin-bottom: 20px;
}

.work__background {
  position: absolute;
  background: #F2F2F2;
  opacity: 0.5;
  border-radius: 116px;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  z-index: -1;
}

.work__background_stripe1 {
  width: 232px;
  height: 1013px;
  left: 4px;
  top: 314px;
}

.work__background_stripe2 {
  width: 232px;
  height: 669px;
  left: 173px;
  top: 59px;
}

.work__background_stripe3 {
  width: 232px;
  height: 923px;
  left: 746px;
  top: 203px;
}

.work__background_stripe4 {
  width: 232px;
  height: 497px;
  right: 253px;
  top: 58px;
}

.work__background_stripe5 {
  width: 232px;
  height: 549px;
  right: -212px;
  top: 118px;
}

.work__title {
  margin-left: 50px;
  margin-bottom: 61px;
}

.work__icon {
  left: -36px;
}

.work__btn {
  padding: 15px 25px;
  margin: 0 auto;
}

.work-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 422px;
}

.work-stage__number {
  font-weight: 900;
  font-size: 144px;
  line-height: 110px;
  min-width: 93px;
  background: repeating-linear-gradient(-75deg, rgba(0, 0, 0, 0.2) 0 3px, transparent 3px 18px), linear-gradient(94.77deg, #8EB7CA 0%, #6BB748 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.work-stage__info {
  max-width: 320px;
  margin-left: 10px;
}

.work-stage__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #4F4F4F;
  margin-top: 0;
  margin-bottom: 12px;
}

.work-stage__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-bottom: 0;
}

/*Обратная связь*/
textarea {
  resize: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:focus:required:invalid {
  border-color: red;
  color: red;
}

input:focus:required:valid {
  color: green;
}

.contact {
  /* background-image: url("assets/background3.svg"), url("assets/background3.svg"); */
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  margin: 0 auto;
}

.contact__title {
  font-weight: 900;
  text-transform: uppercase;
  color: #4F4F4F;
  font-size: 5rem;
  padding: 20px 0 15px 50px;
}

.contact__icon {
  position: relative;
  bottom: 31px;
  left: 51px;
}

.contact__tiny-icon {
  display: none;
}

.contact__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 40px;
  justify-content: center;
}

.contact__forms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 16px;
  width: 506px;
}

.contact__forms_input {
  background: #F2F2F2;
  /* border-radius: 34px; */
  border: none;
  padding: 20px 30px;
  font-weight: 400;
  font-size: 24px;
  border-bottom: #000000 solid 1px;
  text-align: center;
}

.contact__forms_input:focus {
  outline: 0;
  outline-offset: 0;
}

.contact__forms_input::-webkit-input-placeholder {
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_input:-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_input::-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_input::placeholder {
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_textarea {
  font-weight: 400;
  font-size: 24px;
  background: #F2F2F2;
  /* border-radius: 34px; */
  border: none;
  padding: 21px 30px 30px;
  border-bottom: #000000 solid 1px;
  text-align: center;
}

.contact__forms_textarea:focus {
  outline: 0;
  outline-offset: 0;
}

.contact__forms_textarea::-webkit-input-placeholder {
  font-family: 'SF Pro Display';
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_textarea:-ms-input-placeholder {
  font-family: 'SF Pro Display';
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_textarea::-ms-input-placeholder {
  font-family: 'SF Pro Display';
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__forms_textarea::placeholder {
  font-family: 'SF Pro Display';
  color: #BDBDBD;
  font-weight: 400;
  font-size: 24px;
}

.contact__button {
  -webkit-box-shadow: 0px 20px 20px rgba(115, 205, 0, 0.3);
          box-shadow: 0px 20px 20px rgba(115, 205, 0, 0.3);
  width: 200px;
  height: 48px;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0 auto;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contact__point {
  padding: 137px 0 56px;
}

.contact__map {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.cart {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 230px 130px;
      grid-template-columns: 230px 130px;
  border-radius: 10px;
  padding-top: 3px;
}

.cart__info {
  background: #FFFFFF;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.cart__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #4F4F4F;
  text-transform: uppercase;
  margin: 20px 0 13px 20px;
}

.cart__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  margin: 8px 0 0 24px;
  max-width: 175px;
}

.cart__icon {
  margin-right: 10px;
}

.cart__tiny-icon {
  display: none;
}

/* Footer */
.footer {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20% 1fr 1fr;
      grid-template-columns: 20% 1fr 1fr;
  -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
  padding: 50px;
  background-color: #4F4F4F;
  color: #828282;
}
.title-wrap {
    background-color: white;
    position: absolute;
    top: 0;
    width: 100vw;
}
.banner__img-text {
    color: black;
}
.cart__img{
    width: 110%;
    border-radius: 0 10px 10px 0;
}
.product__info-profile > .product__title {
  text-align: left;
}
.in-dev {
  background-color: #ccc !important;
  background-image: repeating-linear-gradient(45deg, #ccc, #ccc 30px, #dbdbdb 30px, #dbdbdb 60px);
}
@media (max-width: 1000px) {
  .agrobot {
    flex-direction: column;
  }
  .product {
    width: 100%;
  }
  .catalog {
    flex-wrap: wrap;
  }
  .header {
    flex-wrap: wrap;
  }
  .header__menu {
    padding: 0;
  }
  .header__logo {
    /* margin-bottom: 5%; */
  }
  .agrobot {
    padding: 5%;
  }
  .title-main {
    font-size: 10vw;
    display: block;
  }
  .contact__title {
    font-size: 10vw;
    display: block;
    padding: 0;
  }
  .contact__map {
    margin-top: 10px;
    max-width: 100%;
    height: unset;
  }
  .contact__button {
    width: 80%;
    margin: 10px auto;
  }
  .contact__forms {
    width: 100%;
  }
  .button-wrap {
    display: flex;
  }
}
