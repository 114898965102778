@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
:root {
  --rs-btn-primary-bg: #8EB7CA;
}
.calcmodal-body {
  font-family: Comfortaa, cursive !important;
  background-color: #0f131a;
  color: var(--theme-contrast);
  overflow: auto;
  padding: 0px !important;
  /* margin: auto; */
  font-size: 16px !important;
  /* border: solid 2px var(--theme-contrast); */
  border-radius: 5px;
  font-weight: bold !important;
  padding: 20px !important;
  max-height: 100vh;
}

.calcmodal-body code {
  background-color: black;
  color: limegreen;
  display: block;
  width: fit-content;
  padding: 10px;
  /* line-break: anywhere; */
  hyphens: auto;
  word-break: break-all;
  max-width: 100%;
}

.apiroute {
  box-shadow: 0px 4px 40px rgba(255, 255, 255, 0.1);
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rs-modal-title {
  white-space: pre-line !important;
}

@media(max-width:650px) {
  .rs-modal-wrapper {
    display: flex;
    align-items: center;
  }

  .buttons-profile {
    display: flex;
    flex-direction: column;
  }

  .buttons-profile>* {
    padding: 5px;
    margin: 5px;
  }

  .inc {
    font-size: larger;
  }

  #btn-confirm {
    width: auto !important;
    padding: 5px 0 !important;
  }
}

.welcome-modals {
  max-width: 600px;
  width: auto;
}

h1,
h2,
h3 {
  user-select: none;
}

.note-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--theme-main);
  margin: 10px 0 0 0;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--theme-text);
  border: 3px solid var(--theme-contrast);
}

.note-div:hover {
  background-color: var(--theme-text);
  color: var(--theme-main);
}

.note-div-child {
  background-color: var(--theme-main);
  margin: 0 0 10px 0;
  padding: 5px;
  border-radius: 5px;
  color: var(--theme-text);
  border: 3px solid var(--theme-contrast);
  border-top: none;
}

.nav-element:hover {
  color: var(--theme-text);
  background-color: var(--theme-main);
}

#colorscale {
  margin: 9px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#colorScale>div {
  border: 2px solid var(--theme-contrast);
  border-radius: 5px;
  padding: 3px;
}

/* html{
    min-height: 100vh;
  } */
body,
#root {
  overflow: auto;
  margin: auto 0;
  min-height: 100vh;
}

.calcmodal-body select,
.calcmodal-body input {
  color: black;
  /* width: 95%; */
  margin: auto;
  padding: 5px;
  background: white;
}

.d-block {
  margin: auto;
  display: block;
}

.calc-title {
  margin-bottom: 15px;
}

.data {
  color: var(--theme-contrast);
  background-color: var(--theme-text);
  min-height: 100vh;
}

.colorSq {
  min-height: 20px;
  border: var(--theme-contrast) 3px;
  min-width: 20px;
  border: 2px solid var(--theme-contrast);
  border-radius: 5px;
  padding: 3px;
}

.rec-result {
  padding: 5px;
  display: block;
  border: 2px solid var(--theme-contrast);
  text-shadow: 1px 1px 1px #000;
}

input,
button {
  font-weight: 700;
}


.data,
input {
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  outline: none;
  /* margin-bottom: 20px; */
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  min-height: fit-content;
  background-color: white;
}

.rs-picker-select {
  /* margin-bottom: 20px; */
}

.data input:focus {
  border: 1px solid #777;
}

.cs-button {
  /* color: var(--theme-contrast); */
  border: 2px solid var(--theme-contrast);
  /* background-color: var(--theme-main); */
  border-radius: 5px;
  padding: 15px;
  display: block;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  font-weight: bolder;
  background-color: #8EB7CA;
}

.rs-btn-primary {
  /* background-color: #8EB7CA; */
}

.user-panel {
  color: black;
  border: 2px solid var(--theme-contrast);
  margin: 10px 0;
  padding: 5px;
  border-radius: 5px;
}

.info-div {
  background-color: var(--theme-secondary);
  padding: 10px;
  border-radius: 10px;
  margin: 9px 0;
  border: 3px solid var(--theme-contrast);
  color: var(--theme-main)
}

.apiroute p {
  line-break: anywhere;
  word-break: break-all;
}

.analysis-img {
  max-width: 100%;
}

.dark {
  color: white;
}

.info-div div {
  max-width: 100%;
  flex-wrap: wrap;
}

.info-div img {
  max-width: 100%;
}

h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datasave-modal {
  height: 100vh;
}

.diagnosis-tile {
  border: 1px solid black;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  background: white;
}

.diagnosis-tile>b {
  background-color: antiquewhite;
  padding: 10px;
  display: block;
  border: 1px solid black;
  border-radius: 10px;
  width: fit-content;
  margin: 5px 0;
}

.history-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.history-wrap>div {
  margin: 10px;
}

.datamodal-wrapper {
  width: fit-content;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
}

.delete-button {
  background-color: red;
  color: white;
  font-weight: bold;
  bottom: 0;
}

.rs-notification a {
  cursor: pointer;
}

.flex-column {
  flex-direction: column !important;
}

/* .flex-stretch {
    align-items: stretch;
  } */
.trans-status {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.trans-table .rs-table-cell>div,
.trans-table button {
  /* background-color: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.nn-base {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.nn-layer {
  display: flex;
  flex-Direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.nn-neuron {
  border-radius: 50%;
  background: green;
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}
.nn-buttons {
  display: flex;
}
.nn-buttons > *, .nn-buttons > div > button {
  padding: 10px !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 15px !important;
  font-weight: bolder !important;
}
.nn-buttons > div {
  padding: 0 !important;
}
.nn-buttons a {
  font-weight: bolder !important;
  color: #575757;
  font-size: 15px !important;
  width: 100%;
  height: 100%;
  padding: 10px !important;
}
.nn-layer-put {
  width: fit-content !important;
}

.nn-tabs {
  height: 90%;
}
.nn-tabs > .rs-tabs-content, .nn-tabs > .rs-tabs-content > .rs-tab-panel {
  height: 100%;
}

.nn-tabs > div > button {
  width: 25% !important;
}

.nn-card {
  border-radius: 20px;
  width: 300px;
  min-height: 300px;
  box-shadow: 2px 6px 20px 0px rgb(0 0 0 / 22%);
  background: repeating-linear-gradient(45deg,#74fd9d, #74fd9d 10px, #ffffff 10px, #ffffff 20px);
  padding: 15px;
  margin: 5px;
}

.ens-tabs > div > button {
  width: 33% !important;
}
.ens-tabs {
  height: 90%;
}
.ens-tabs > .rs-tabs-content, .ens-tabs > .rs-tabs-content > .rs-tab-panel {
  height: 100%;
}