.auth-wrapper-parent {
  background: linear-gradient(135deg, rgba(184,198,223,1) 0%,rgba(142,183,202,1) 100%);
  /* background-image: url('./55.jpg'); */
  width: 100vw;
  height: 100vh;
  background-size: cover;
}
.auth-wrapper {
  backdrop-filter: grayscale(0.7) blur(2px);
  width: 100vw;
  height: 100vh;
  color: darkslategrey;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
.auth-wrapper > div {
  background-color: rgba(240, 248, 255, 0.774);
  box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
  margin: 2% 20px;
  border-radius: 15px;
  padding: 15px 30px;
  min-width: 40%;
  height: fit-content;
}
.auth-wrapper button+a {
  width: 40%;
  background-color: #8EB7CA;
  margin: auto;
}
.auth-flex {
  display: flex;
  justify-content: space-evenly;
}
.auth-wrapper #changetype {
  width: fit-content;
}
.rs-icon-eye, .rs-icon-eye-slash {
  color: black;
}
.profile-wrapper {
  background: linear-gradient(135deg, rgba(184,198,223,1) 0%,rgba(142,183,202,1) 100%);;
  color: darkslategrey;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.profile-wrapper > #logo {
  height: 100px;
}
.profile-wrapper > #user {
  margin-top: 5%;
  /* width: 80%; */
  background-color: aliceblue;
  box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.user-tile {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.user-tile > div {
  display: flex;
  flex-direction: column;
}
/* .rs-btn {
  background-color: #8EB7CA;
} */
.catalog-profile {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}
.product-profile {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 4px 40px rgb(0 0 0 / 10%);
  border-radius: 20px;
  text-align: center;
  padding: 2.5em 0.5em;
  align-items: center;
  width: 40%;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.product-profile:hover {
  background-color: aliceblue;
  box-shadow: 2px 4px 40px rgb(0 0 0 / 10%);
}
.product__icon-profile {
  width: 50px;
  height: 50px;
  background-size: cover;
  margin-right: 20px;
}
.product__description-profile {
  font-weight: 500;
  color: #BDBDBD;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}
#regkeys-list, #messages-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
#regkeys-list > div {
  /* border: black 3px solid; */
  margin: 5px;
  padding: 10px;
  color: black;
  border-radius: 15px;
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
}
#btn-confirm{
  margin: 0 5px;
}
.message{
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
  padding: 10px;
  border-radius: 10px;
  max-width: 40%;
  height: fit-content;
}
.message > p{
  font-style: italic;
  word-wrap: break-word;
  overflow: hidden;
  white-space: wrap; 
}
.fa-icon-profile {
  color: #8EB7CA;
  height: 50px;
  margin-right: 20px;
}
@media(max-width: 1000px) {
  .product-profile {
    width: 95%;
  }
  .user-tile {
    flex-wrap: wrap;
  }
  .product__icon-profile {
    /* width: fit-content; */
    z-index: 100;
  }
}
.auth-flex button {
  width: 90%;
  padding: 15px;
}
.auth-modal input, .auth-modal .rs-input-group {
  width: 90%;
  margin: auto;
}
.auth-strip {
  background-color: #8EB7CA;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
}
.auth-strip button {
  padding: 15px 30px;
  color: black;
  border-radius: 10px;
  width: 40%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
/* .auth-modal h5 {
  right: -7%;
  position: relative;
} */